<template>
  <transition name="modal">
    <div class="modal-mask" v-on:click="$emit('close')">
      <div class="modal-wrapper modal-midi signInModal" v-on:click.stop>
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal-header-icon">
              <icon name="user" scale="2"></icon>
            </div>
            <div class="modal-header-title">Вход в систему</div>
            <div class="modal-close" @click="$emit('close')">
              <icon name="times"></icon>
            </div>
          </div>
          <div class="modal-body">
            <form id="signinForm" class="inputForm" v-on:submit.prevent.stop="signIn">
              <div class="inputBlock" :class="{ error: errors.email }">
                <icon name="envelope-open" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="text"
                    name="email"
                    v-model="signInData.email"
                    class="inputField"
                    autocomplete="off"
                    placeholder="Введите электронный адрес"
                  />
                  <p class="errorField" v-if="errors.email">{{ errors.email }}</p>
                </div>
              </div>

              <div class="inputBlock" :class="{ error: errors.password }">
                <icon name="key" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="password"
                    name="password"
                    v-model="signInData.password"
                    class="inputField"
                    autocomplete="off"
                    placeholder="Введите пароль"
                  />
                  <p class="errorField" v-if="errors.password">{{ errors.password }}</p>
                </div>
              </div>

              <a href="#" class="forgetPassword" v-on:click.prevent.stop="$emit('forgetPassword')">Забыли пароль?</a>
              <div class="inputButtonBlock">
                <button type="submit" class="btn btnAuth">Вход</button>
                <button type="button" class="btn btnRegister" @click="$emit('signUp')">Зарегистрироваться</button>
              </div>
              <!-- <div class="inputBlockDevider">
								<div class="deviderLine"></div>
								<span>или</span>
								<div class="deviderLine"></div>
							</div> -->
              <!-- <div class="inputBlockSocial">
								<a href="#">Войти через Google</a>
								<a href="#">Войти через Facebook</a>
							</div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SignInModal",
  data() {
    return {
      endPoint: "/auth/signin",
      signInData: {
        email: "",
        password: "",
        rememberMe: "",
      },
      errors: {
        email: "",
        password: "",
      },
      errorsList: {
        "0": "Данное поле не должно быть пустым",
        "1": "Адрес электронной почты введен некорректно",
        "2": "Такой учетной записи – не существует",
        "3": "Пароль введен не верно",
        "4": "Учетная запись не активирована",
        "5": "Авторизация прошла успешно",
      },
    };
  },
  methods: {
    signIn() {
      this.errors.email = !this.signInData.email ? this.errorsList["0"] : "";
      this.errors.password = !this.signInData.password ? this.errorsList["0"] : "";

      if (this.signInData.email && this.signInData.password) {
        this.$http.post(this.endPoint, this.signInData).then((response) => {
          if (response.data.token) {
            this.$store.dispatch("signin", response.data.token);
            this.$emit("close");
          } else {
            this.errors.email = response.data.info.email ? this.errorsList[response.data.info.email] : "";
            this.errors.password = response.data.info.password ? this.errorsList[response.data.info.password] : "";
          }
        });
      }
    },
  },
};
</script>

<style lang="sass">
@import "./modalStyle.sass"
</style>

<style lang="sass" scoped>
.signInModal
	background-color: #00ab9e
#signinForm
	padding: 1rem 4rem 2rem
	@media screen and (max-width: 426px)
		padding: 1rem 1rem 2rem
	& .forgetPassword
		display: block
		width: fit-content
		color: #ffffff
		text-decoration: none
		border-bottom: 1px solid transparent
		margin-bottom: 1rem
		transition: all 0.5s
		&:hover
			color: #ddd
			border-bottom: 1px solid #fff

	& .inputButtonBlock
		display: flex
		justify-content: space-between
		margin-bottom: 1rem
		@media screen and (max-width: 600px)
			flex-direction: column
		& button
			display: flex
			align-items: center
			justify-content: center
			font-size: 1.1rem
			height: 35px
			width: calc(50% - 1rem)
			border: 1px solid transparent
			cursor: pointer
			transition: all 0.5s
			@media screen and (max-width: 600px)
				width: 100%
			&.btnAuth
				color: #00ab9e
				background: #fff
				@media screen and (max-width: 600px)
					margin-bottom: 1rem
				&:hover
					color: #ddd
			&.btnRegister
				color: #fff
				background: #00ab9e
				border-color: #fff
				&:hover
					color: #ddd

	& .inputBlockDevider
		display: flex
		align-items: center
		justify-content: space-between
		color: #fff
		margin-bottom: 1rem
		& .deviderLine
			display: block
			height: 1px
			width: 100%
			background: #fff
		& span
			padding: 0 1rem

	& .inputBlockSocial
		display: flex
		flex-direction: column
		align-items: center
		& a
			display: flex
			justify-content: center
			align-items: center
			height: 40px
			width: 80%
			border: 1px solid #fff
			color: #fff
			font-size: 1.1rem
			margin-bottom: 1rem
			@media screen and (max-width: 600px)
				width: 100%
			&::last-child
				margin-bottom: 0
// .help-block
// 	display: flex
// 	justify-content: space-between
// 	margin-bottom: 1.5rem
// 	font-size: 16px
// 	.forgetPassword
// 		color: #1abb9c
// 		text-decoration: underline
// .signUp-block
// 	display: block
// 	width: 100%
// 	text-align: center
// 	font-size: 16px
// 	margin-top: 1.5rem
// 	a
// 		color: #1abb9c
// 		text-decoration: underline
</style>
