export default {
  menu_items: {
    stl_viewer: {
      ru: "STL Viewer",
      en: "STL Viewer",
    },
    visualization: {
      ru: "3D визуализации",
      en: "3D visualizations",
    },
    library3d: {
      ru: "3D библиотеки",
      en: "3D Library",
    },
    photo_bank: {
      ru: "Фотобанк",
      en: "Photo bank",
    },
    demo_models: {
      ru: "Демо модели",
      en: "Demo models",
    },
    signin: {
      ru: "ВХОД В СИСТЕМУ",
      en: "Signin",
    },
    profile: {
      ru: "Профиль",
      en: "Profile",
    },
    signout: {
      ru: "Выход",
      en: "Signout",
    },
  },
};
