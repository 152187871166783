<template>
  <a href="#" id="scrollTop" v-on:click.stop.prevent="scrollToTop">
    <icon name="arrow-up"></icon>
  </a>
</template>

<script>
import { scrollTop } from "./scrollFunc.js";
export default {
  name: "ScrollTop",
  data() {
    return {};
  },
  methods: {
    scrollToTop() {
      const sTop = document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop(window, sTop, 0, 500, "ease");

      console.log(sTop);
    },
  },
};
</script>

<style lang="sass">
#scrollTop
    display: block
    position: fixed
    bottom: 20px
    right: 30px
    background: #3339
    border: 1px solid transparent
    border-radius: 999px
    width: 55px
    height: 55px
    text-align: center
    line-height: 55px
    color: #fff
    font-size: 20px
    z-index: 999
    transition: all 0.3s
    &:hover
        background: #333333de
</style>
