<template>
  <transition name="modal">
    <div class="modal-mask" v-on:click="$emit('close')">
      <div class="modal-wrapper modal-midi signUpModal" v-on:click.stop>
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal-header-icon">
              <icon name="user" scale="2"></icon>
            </div>
            <div class="modal-header-title">Регистрация</div>
            <div class="modal-close" @click="$emit('close')">
              <icon name="times"></icon>
            </div>
          </div>
          <div class="modal-body">
            <form id="signupForm" class="inputForm" v-on:submit.prevent.stop="signUp" v-if="!isSuccess">
              <div class="inputBlock" :class="{ error: errors.userName }">
                <icon name="user" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="text"
                    name="userName"
                    v-model="signUpData.userName"
                    class="inputField"
                    placeholder="Имя пользователя"
                    autocomplete="off"
                  />
                  <p class="errorField" v-if="errors.userName">{{ errors.userName }}</p>
                </div>
              </div>
              <div class="inputBlock" :class="{ error: errors.email }">
                <icon name="envelope-open" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="email"
                    name="email"
                    v-model="signUpData.email"
                    class="inputField"
                    placeholder="Введите электронный адрес"
                    autocomplete="off"
                  />
                  <p class="errorField" v-if="errors.email">{{ errors.email }}</p>
                </div>
              </div>
              <div class="inputBlock" :class="{ error: errors.password }">
                <icon name="key" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="password"
                    name="password"
                    v-model="signUpData.password"
                    class="inputField"
                    placeholder="Введите пароль"
                    autocomplete="off"
                  />
                  <p class="errorField" v-if="errors.password">{{ errors.password }}</p>
                </div>
              </div>
              <div class="inputBlock" :class="{ error: errors.passwordRetry }">
                <icon name="key" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="password"
                    name="passwordRetry"
                    v-model="signUpData.passwordRetry"
                    class="inputField"
                    placeholder="Введите пароль повторно"
                    autocomplete="off"
                  />
                  <p class="errorField" v-if="errors.passwordRetry">{{ errors.passwordRetry }}</p>
                </div>
              </div>
              <div class="inputBlockTermsOfUse">
                <input type="checkbox" id="termsOfUse" name="termsOfUse" v-model="signUpData.termsOfUse" />
                <label for="termsOfUse"
                  >Я принимаю <a href="#" v-on:click.prevent.stop>Пользовательское соглашение</a></label
                >
              </div>
              <p class="errorField" v-if="errors.termsOfUse">{{ errors.termsOfUse }}</p>
              <button type="submit" class="btn btnRegistration">Зарегистрироваться</button>
              <small class="signInLink">
                Вы уже зарегистрированы? <br />
                <a href="#" v-on:click.prevent.stop="$emit('signIn')">Авторизация!</a>
              </small>
            </form>
            <div class="successSignup" v-else>
              <div>
                Для завершения процедуры регистрации нажмите на ссылку в письме, отправленную на Ваш почтовый ящик
              </div>
              <h3 class="signupEmail">{{ signUpData.email }}</h3>
              <div>
                <span>Если вы не получили письмо, на всякий случай проверьте:</span>
                <ul>
                  <li>Нет ли ошибок в электронном адресе</li>
                  <li>Не попало ли письмо в папку «Спам»</li>
                </ul>
                <span>
                  Если у Вас возникли вопросы, связанные с регистрацией или доступом в личную страницу, пишите по адресу
                  <br />
                  dentabula@gmail.com
                  <br />
                  мы разберемся и обязательно поможем.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SignInModal",
  data() {
    return {
      endPoint: "/auth/signup",
      isSuccess: false,
      signUpData: {
        userName: "",
        email: "",
        password: "",
        passwordRetry: "",
        termsOfUse: false,
      },
      errors: {
        userName: "",
        email: "",
        password: "",
        passwordRetry: "",
        termsOfUse: "",
      },
      errorsList: {
        "0": "Данное поле не должно быть пустым",
        "1": "Ваш логин должен содержать не менее 3 и не более 12 символов, состоять из латинских букв и цифр",
        "2": "Адрес электронной почты введен некорректно",
        "3":
          "Ваш пароль должен содержать не менее 6 и не более 16 символов, состоять из латинских букв и минимум 1-й цифры",
        "4": "Введенные Вами пароли не совпадают",
        "5": "Перед созданием учетной записи необходимо ознакомиться с «Условиями использования»",
        "6": "Учетная запись по данному адресу электронной почты – уже существует",
        "7": "Регистрация прошла успешно",
        "8": "Ошибка при создании учетной записи",
      },
    };
  },
  methods: {
    signUp() {
      this.errors.userName = !this.signUpData.userName ? this.errorsList["0"] : "";
      this.errors.email = !this.signUpData.email ? this.errorsList["0"] : "";
      this.errors.password = !this.signUpData.password ? this.errorsList["0"] : "";
      this.errors.passwordRetry = !this.signUpData.passwordRetry ? this.errorsList["0"] : "";
      this.errors.termsOfUse = !this.signUpData.termsOfUse ? this.errorsList["5"] : "";

      if (
        this.signUpData.userName &&
        this.signUpData.email &&
        this.signUpData.password &&
        this.signUpData.passwordRetry &&
        this.signUpData.termsOfUse
      ) {
        this.$http.post(this.endPoint, this.signUpData).then((response) => {
          console.log(response);
          if (response.data.success) {
            this.isSuccess = true;
          } else if (response.data.info) {
            this.errors.userName = response.data.info.userName ? this.errorsList[response.data.info.userName] : "";
            this.errors.email = response.data.info.email ? this.errorsList[response.data.info.email] : "";
            this.errors.password = response.data.info.password ? this.errorsList[response.data.info.password] : "";
            this.errors.passwordRetry = response.data.info.passwordRetry
              ? this.errorsList[response.data.info.passwordRetry]
              : "";
            this.errors.termsOfUse = response.data.info.termsOfUse
              ? this.errorsList[response.data.info.termsOfUse]
              : "";
          } else {
            console.log(response);
          }
        });
      }
    },
  },
};
</script>

<style lang="sass">
@import "./modalStyle.sass"
</style>

<style lang="sass" scoped>
.signUpModal
    background: #0072bc

#signupForm
	padding: 1rem 4rem 2rem
	@media screen and (max-width: 426px)
		padding: 1rem 1rem 2rem
	& .inputBlockTermsOfUse
		display: flex
		align-items: center
		color: #fff
		margin-bottom: 1rem
		& input
			margin-right: 0.5rem
		& a
			color: #fff
			border-bottom: 1px solid #fff
	& .btnRegistration
		display: flex
		align-items: center
		justify-content: center
		width: 100%
		height: 45px
		font-size: 1.1rem
		color: #0072bc
		background: #fff
		border: 1px solid transparent
		margin-bottom: 1rem
		cursor: pointer
	& .signInLink
		text-align: center
    	line-height: 18px
		a
			color: #fff
			font-size: 1rem
.successSignup
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	color: #fff
	padding: 2rem 1rem
	text-align: center
	min-height: 200px
	& .signupEmail
		margin: 1rem 0
    	font-size: 160%
	ul
		list-style: disc
		display: block
		padding-left: 4rem
		text-align: left
		margin-bottom: 1rem
// .signUp-inputs
// 	display: grid
// 	grid-template-columns: 1fr 1fr
// 	grid-column-gap: 2rem
// .signUp-block
// 	display: block
// 	width: 100%
// 	text-align: center
// 	font-size: 16px
// 	margin-top: 1.5rem
// 	a
// 		color: #1abb9c
// 		text-decoration: underline
</style>
