<template>
  <div id="mainMenu">
    <div class="mainMenuWrapper">
      <a href="#" class="mobileMenu" v-on:click.prevent.stop="mobileMenu = !mobileMenu">
        <icon name="bars" scale="1.2"></icon>
      </a>
      <router-link :to="{ name: 'landing' }" class="mainMenuLogo">
        <img src="./../../assets/images/dentabula-logo.png" class="logo" alt="Dentabula logo" />
      </router-link>
      <nav :class="{ mobileMenuOpen: mobileMenu }">
        <router-link :to="{ name: 'stlViewer' }" class="mainMenuLink">
          {{ translate.menu_items.stl_viewer[currentLang] }}
        </router-link>
        <router-link :to="{ name: 'visualization' }" class="mainMenuLink">
          {{ translate.menu_items.visualization[currentLang] }}
        </router-link>
        <router-link :to="{ name: 'library3d' }" class="mainMenuLink">
          {{ translate.menu_items.library3d[currentLang] }}
        </router-link>
        <router-link :to="{ name: 'photoBank' }" class="mainMenuLink">
          {{ translate.menu_items.photo_bank[currentLang] }}
        </router-link>
        <router-link :to="{ name: 'demoModels' }" class="mainMenuLink">
          {{ translate.menu_items.demo_models[currentLang] }}
        </router-link>
      </nav>
      <div class="menuAuth">
        <div class="languageToogle">
          <a href="#" class="mainMenuLink" v-on:click.prevent.stop>
            <icon name="globe"></icon>
          </a>
          <ul v-if="isSelectLanguage" class="languageToogleDropdown">
            <li><a href="#" v-on:click.prevent.stop="selectLanguage('ru')">ru</a></li>
            <li><a href="#" v-on:click.prevent.stop="selectLanguage('en')">en</a></li>
          </ul>
        </div>
        <a href="#" class="authLink" v-if="!isLoggedIn" v-on:click.prevent.stop="isSingnInM = true">
          <icon name="user" class="mr-50"></icon>
          <span>{{ translate.menu_items.signin[currentLang] }}</span>
        </a>
        <router-link :to="{ name: 'profile' }" class="authLink" v-if="isLoggedIn">
          <icon name="user" class="mr-50"></icon>
          <span>{{ translate.menu_items.profile[currentLang] }}</span>
        </router-link>
        <a href="#" class="authLink" v-if="isLoggedIn" v-on:click.prevent.stop="signout">
          <span>{{ translate.menu_items.signout[currentLang] }}</span>
        </a>
      </div>
    </div>
    <signInModal
      v-if="isSingnInM"
      @close="isSingnInM = false"
      @signUp="
        isSingnInM = false;
        isSingnUpM = true;
      "
      @forgetPassword="
        isSingnInM = false;
        isForgetPassword = true;
      "
    ></signInModal>
    <signUpModal
      v-if="isSingnUpM"
      @close="isSingnUpM = false"
      @signIn="
        isSingnInM = true;
        isSingnUpM = false;
      "
      @infoModal="
        isSingnUpM = false;
        afterSignUp = true;
      "
    ></signUpModal>
    <forgetPasswordModal v-if="isForgetPassword" @close="isForgetPassword = false"></forgetPasswordModal>
    <scrollTop></scrollTop>
  </div>
</template>

<script>
import signInModal from "./../modals/signInModal.vue";
import signUpModal from "./../modals/signUpModal.vue";
import forgetPasswordModal from "./../modals/forgetPasswordModal.vue";
import scrollTop from "./../scrollTop/scrollTop.vue";
import translate from "./translate.js";

export default {
  name: "mainMenu",
  components: { signInModal, signUpModal, forgetPasswordModal, scrollTop },
  props: ["isSignin", "isSignup"],
  data() {
    return {
      mobileMenu: false,

      isSingnInM: false,
      isSingnUpM: false,
      isForgetPassword: false,

      isSelectLanguage: false,
      translate: translate,
    };
  },
  methods: {
    signout() {
      this.$store.dispatch("signout");
      this.$router.push("/");
    },
    selectLanguage(lang) {
      this.$store.dispatch("changeLanguage", lang);
      this.isSelectLanguage = false;
    },
    chekStatus() {
      this.isSingnInM = !!this.isSignin;
      this.isSingnUpM = !!this.isSignup;
    },
  },
  watch: {
    $route: "chekStatus",
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    currentLang() {
      return this.$store.getters.currentLang;
    },
  },
  created() {
    this.chekStatus();
  },
};
</script>

<style lang="sass">
#mainMenu
	display: flex
	align-items: center
	justify-content: center
	height: 60px
	background: #ebebeb
	border-bottom: 1px solid #00ab9e
	@media screen and (max-width: 1024px) and (min-width: 768px)
		height: 100px
	.mainMenuWrapper
		display: flex
		position: relative
		justify-content: space-between
		height: inherit
		margin: 0 auto
		width: 100%
		max-width: 1024px
		@media screen and (max-width: 1024px) and (min-width: 768px)
			flex-direction: column
		& .mobileMenu
			display: none
			align-items: center
			height: inherit
			padding: 0 1.5rem
			color: #00ab9e
			@media screen and (max-width: 767px)
				display: flex
		& .mainMenuLogo
			display: flex
			align-items: center
			justify-content: center
			padding: 0 0.5rem
			height: inherit
			& img.logo
				display: block
				width: auto
				height: 20px


		& nav
			display: flex
			align-items: center
			justify-content: center
			width: 100%
			height: inherit
			@media screen and (max-width: 767px)
				display: none
				position: absolute
				top: 100%
				z-index: 9
				flex-direction: column
				background: #fff
				height: auto
				&.mobileMenuOpen
					display: flex
					a.mainMenuLink
						width: 100%
						padding: 0.5rem 0.5rem
			& a
				display: flex
				align-items:center
				height: inherit
				color: #3a3939
				&.mainMenuLink
					display: flex
					align-items: center
					justify-content: center
					text-align: center
					padding: 0 0.5rem
					width: max-content
					height: 100%
					&.router-link-active
						color: #00ab9e
						font-weight: bold

					&:hover
						color: #00ab9e




		& .menuAuth
			display: flex
			align-items: center
			justify-content: center
			height: inherit
			& .languageToogle
				display: flex
				align-items: center
				justify-content: center
				height: 100%
				position: relative
				& a
					display: flex
					align-items: center
					justify-content: center
					padding: 0 0.5rem
					height: 100%
					color: #00ab9e
					&:hover
						color: #46dbd0


				& ul.languageToogleDropdown
					display: block
					position: absolute
					top: 100%
					right: 0
					list-style: none
					background: #fff
					width: fit-content
					& li a
						display: block
						padding: 0.25rem 1rem
						border-bottom: 1px solid #ddd
						&::last-child
							border-bottom: none




			& .authLink
				display: flex
				align-items: center
				justify-content: center
				height: 100%
				padding: 0 0.5rem
				color: #00ab9e
				width: max-content
				&:hover
					color: #46dbd0
				@media screen and (max-width: 767px)
					span
						display: none
</style>
