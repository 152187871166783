<template>
  <transition name="modal">
    <div class="modal-mask" v-on:click="$emit('close')">
      <div class="modal-wrapper modal-mini forgetPasswordModal" v-on:click.stop>
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal-header-icon">
              <icon name="user" scale="2"></icon>
            </div>
            <div class="modal-header-title">Восстановление пароля</div>
            <div class="modal-close" @click="$emit('close')">
              <icon name="times"></icon>
            </div>
          </div>
          <div class="modal-body">
            <form id="forgetPasswordForm" class="inputForm" v-on:submit.prevent.stop="forgetPassword" v-if="!isSend">
              <div class="inputBlock" :class="{ error: errors.email }">
                <icon name="user" scale="1.3" class="inputIcon"></icon>
                <div class="inputComponent">
                  <input
                    type="email"
                    name="email"
                    v-model="forgetPasswordData.email"
                    class="inputField"
                    placeholder="e-mail"
                    autocomplete="off"
                  />
                  <p class="errorField" v-if="errors.email">{{ errors.email }}</p>
                </div>
              </div>
              <button type="submit" class="btn btn-light wide">Отправить запрос</button>
            </form>
            <div v-else class="successMessage">
              На вашу почту отправлено письмо восстановления пароля
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ForgetPassword",
  data() {
    return {
      endPoint: "/auth/restore",
      isSend: false,
      forgetPasswordData: {
        email: "",
      },
      errors: {
        email: "",
      },
      errorsList: {
        "0": "Данное поле не должно быть пустым",
        "1": "Адрес электронной почты введен некорректно",
        "2": "Такой учетной записи – не существует",
        "3": "Учетная запись не активирована!",
      },
    };
  },
  methods: {
    forgetPassword() {
      this.errors.email = !this.forgetPasswordData.email ? this.errorsList["0"] : "";

      if (this.forgetPasswordData.email) {
        this.$http.post(this.endPoint, this.forgetPasswordData).then((response) => {
          if (response.data.success) {
            this.isSend = true;
          } else {
            this.errors.email = response.data.info ? this.errorsList[response.data.info] : "";
          }
        });
      }
    },
  },
};
</script>

<style lang="sass">
@import "./modalStyle.sass"
.forgetPasswordModal
	background-color: #00ab9e
	#forgetPasswordForm
		padding: 1rem 4rem 2rem
		@media screen and (max-width: 426px)
			padding: 1rem 1rem 2rem
	.successMessage
		text-align: center
		padding: 2rem 0rem
		color: #fff
</style>
